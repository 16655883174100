<template>
    <div class="p-3">
            Manager: graf - prodaja, uspešnost, statistika ...
    </div>
</template>

<script>
import FormTableValues from "@/components/general/elements/formElements/FormTableValues";

export default {
    name: "Manager",
    components: {FormTableValues},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
