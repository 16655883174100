<template>
    <div>
        <!-- table -->
        <b-table
            id="main-table"
            :fields="tableFields"
            :items="table.items"
            bordered
            hover
            striped
            outlined
            sticky-header="270px"
            table-class="text-center"
            table-variant="white"
            thead-tr-class="bg-primary"
        >
<!--            head-row-variant="white"-->
<!--            thead-tr-class="bg-primary"-->
            <!-- header -->
            <template #head(value_a)="head">
                <div class="text-center font-weight-normal">
                    {{head.field.label}}
                </div>
            </template>
            <template #head(value_b)="head">
                <div class="text-center font-weight-normal">
                    {{head.field.label}}
                </div>
            </template>
            <template #head(value_c)="head">
                <div class="text-center font-weight-normal">
                    {{head.field.label}}
                </div>
            </template>
            <!-- header add -->
            <template #head(action)="head">
                <b-icon-plus-square
                    v-b-tooltip.hover
                    :title="$t('form.default')"
                    scale="1.2"
                    style="cursor: pointer; margin-right: 3px;"
                    variant=""
                    @click="itemAdd()"
                ></b-icon-plus-square>
            </template>
            <!-- value a -->
            <template v-slot:cell(value_a)="row">
                <b-input v-model="row.item.value_a"></b-input>
            </template>
            <!-- value b -->
            <template v-slot:cell(value_b)="row">
                <div class="d-flex justify-content-center align-items-center">
                    <b-input v-model="row.item.value_b"></b-input>
                </div>
            </template>
            <!-- value c -->
            <template v-slot:cell(value_c)="row">
                <b-input v-model="row.item.value_c" :type="input_c_type"></b-input>
            </template>
            <!-- actions -->
            <template v-slot:cell(action)="row">
                <div class="d-flex justify-content-center align-items-center" style="height:40px">
                    <!-- default -->
                    <b-icon-suit-heart
                        v-if="!row.item.default"
                        v-b-tooltip.hover
                        :title="$t('form.default')"
                        scale="1.2"
                        style="cursor: pointer; margin-right: 3px;"
                        variant="secondary"
                        @click="itemDefault(row.item)"
                    ></b-icon-suit-heart>
                    <b-icon-suit-heart-fill
                        v-if="row.item.default"
                        v-b-tooltip.hover
                        :title="$t('form.default')"
                        scale="1.2"
                        style="cursor: pointer; margin-right: 3px;"
                        variant="secondary"
                        @click="itemDefault(row.item)"
                    ></b-icon-suit-heart-fill>
                    <!-- deprecated -->
                    <b-icon
                        v-b-tooltip.hover
                        :icon="row.item.deprecated ? 'eye-slash-fill' : 'eye-slash'"
                        :title="$t('form.deprecated')"
                        scale="1.2"
                        style="cursor: pointer; margin: 0 3px 0 3px;"
                        variant="secondary"
                        @click="itemDeprecated(row.item)"
                    ></b-icon>
                    <!-- delete -->
                    <b-icon-trash
                        v-if="!row.item.system"
                        v-b-tooltip.hover
                        :title="$t('form.delete')"
                        scale="1.2"
                        style="cursor: pointer; margin-left: 3px;"
                        variant="danger"
                        @click="itemDelete(row.item)"
                    ></b-icon-trash>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    name: "FormTableValues",
    components: {},
    props: {
        extended: {
            type: Boolean,
            default() {
                return false
            }
        },
        json_key: {
            type: String,
            default() {
                return 'table_values'
            }
        },
        column_a_text: {
            type: String,
            default() {
                return 'Column A'
            }
        },
        column_b_text: {
            type: String,
            default() {
                return 'Column B'
            }
        },
        column_c_text: {
            type: String,
            default() {
                return 'Column C'
            }
        },
        input_c_type: {
            type: String,
            default() {
                return 'text'
            }
        },
        emptyRowsNumber: {
            type: Number,
            default() {
                return 1
            }
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            table: {
                items: [],
                fields: [
                    {
                        key: 'value_a',
                        label: this.column_a_text,
                        sortable: false,
                        extended: true,
                        // thStyle: {width: '33%'}
                    },
                    {
                        key: 'value_b',
                        label: this.column_b_text,
                        sortable: false,
                        // thStyle: {width: '33%'}
                    },
                    {
                        key: 'value_c',
                        label: this.column_c_text,
                        sortable: false,
                        // thStyle: {width: '33%'}
                    },
                    {
                        key: 'action',
                        label: '',
                        sortable: false,
                        thStyle: {width: '80px'}
                    },
                ],
            },
        }
    },
    beforeMount() {
        this.setItems(this.record[this.json_key]);
    },
    methods: {
        // <-- table items -->
        defaultItemAdd() {
            if (this.table.items.length > 0) {
                return;
            }
            // <--  -->
            for (let i = 0; i < this.emptyRowsNumber; i++) {
                this.itemAdd();
            }
        },
        itemAdd() {
            let index = this.table.items.length;
            this.table.items.push(
                {
                    key: this.__createUniqueKey(index),
                    value_a: null,
                    value_b: null,
                    value_c: null,
                    default: false,
                    deprecated: false
                }
            );
        },
        setItems(record) {
            // <-- default items -->
            if (record === undefined) {
                this.table.items = [];
                this.defaultItemAdd();
                return;
            }
            // <-- set items -->
            let items = [];
            // <-- set items from record -->
            record.forEach((row, index) => {
                items.push(
                    {
                        key: this.__createUniqueKey(index),
                        index: row.index,
                        value_a: row.value_a,
                        value_b: row.value_b,
                        value_c: row.value_c,
                        default: row.default,
                        deprecated: row.deprecated
                    }
                );
            });
            // <--  -->
            this.$set(this.table, 'items', items);
        },
        itemDelete(row) {
            let items = this.table.items;
            let key = row.key;
            // <-- find row index -->
            let index = -1;

            items.find((n, indexTemp) => {
                if (n.key === key) {
                    index = indexTemp;
                }
            });
            // <-- remove item -->
            this.table.items.splice(index, 1);
        },
        itemDefault(row) {
            let items = this.table.items;
            let key = row.key;
            let valueOld;
            // <-- find row index -->
            items.find((n, index) => {
                valueOld = this.table.items[index].default;
                this.table.items[index].default = false;
                if (n.key === key) {
                    this.table.items[index].default = !valueOld;
                }
            });
            this.itemsOrder();
        },
        itemDeprecated(row) {
            let items = this.table.items;
            let key = row.key;
            let valueOld;
            // <-- find row index -->
            items.find((n, index) => {
                valueOld = this.table.items[index].deprecated;
                if (n.key === key) {
                    this.table.items[index].deprecated = !valueOld;
                }
            });
        },
        itemsOrder() {
            let items = [];
            // <-- default item -->
            this.table.items.forEach(item => {
                if (item.default) {
                    items.push(item);
                }
            });
            // <-- non default item -->
            this.table.items.forEach(item => {
                if (!item.default) {
                    items.push(item);
                }
            });
            // <--  -->
            this.$set(this.table, 'items', items);
        },
        // <-- record -->
        setRecords() {
            let record = [];
            this.table.items.forEach((item, index) => {
                record.push({
                    index: index,
                    value_a: item.value_a,
                    value_b: item.value_b,
                    value_c: item.value_c,
                    default: item.default,
                    deprecated: item.deprecated,
                });
            });
            // <--  -->
            let data = {
                key: this.json_key,
                values: record
            };
            this.$emit('eventUpdateRecord', data);
        },
    },
    computed: {
        tableFields() {
            let fields = [];
            this.table.fields.forEach(field => {
                if (this.extended || field.extended === undefined || field.extended === false) {
                    fields.push(field);
                }
            });
            return fields;
        },
    },
    filters: {},
    watch: {
        'table.items': {
            handler: function (value, oldValue) {
                this.setRecords();
            },
            deep: true
        },
        'record': {
            handler: function (value, oldValue) {
                this.setItems(value[this.json_key]);
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
